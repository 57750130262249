<template>
  <pmis-page ref="page" :search-project-visible="false" @search-click="onSearch">
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-content-box>
            <pmis-tab-box>
              <template v-slot:title>
                노임대장
              </template>
              <template v-slot:title-right>
                <iui-button value="엑셀다운로드" @click="onExceldownload" />
                <iui-button value="지급명세서 출력" @click="onReportViewer" />
              </template>
              <pmis-content-box>
                <template v-slot:header-left>
                  <div>출역년월</div>
                  <iui-datepicker
                    type="month"
                    name="investMm"
                    :value="searchInfo.investMm"
                    :isIconClear="false"
                    @change="searchInfo.investMm = $event"
                  />
                  <i class="prev-arrow" @click="onClickDate('PREV')" />
                  <i class="post-arrow" @click="onClickDate('POST')" />
                  <div>성명</div>
                  <iui-text
                    type="search"
                    :value="searchInfo.searchNm"
                    @change="searchInfo.searchNm = $event.target.value"
                    @enter="onSearch()"
                  />
                  <iui-button value="검색" @click="onSearch" />
                </template>
                <ib-sheet
                  :uid="_uid"
                  :options="Options"
                  :loadSearchData="loadSearchData"
                  :events="{
                    onSearchFinish,
                    onRenderFirstFinish,
                  }"
                  @loadSheet="loadSheet"
                />
              </pmis-content-box>
            </pmis-tab-box>
          </pmis-content-box>
          <iui-modal name="ReportViewerModal" title="지급명세서 출력" size-type="size6">
            <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
          </iui-modal>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="PayrollViewerModal" title="급여 지급 명세서" size-type="size6">
      <report-viewer :fomNo="fomNo" :ozContent="ozContent" />
    </iui-modal>
  </pmis-page>
</template>

<script>
import ReportViewer from '@/view/bidding/components/ReportViewer.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import options from './components/sheetOption/WagesLedger.js';
import {selectLastInvestMm, selectList, selectPayStateList} from './api/WagesLeder.js';
import FOM_NO from '@/constants/fomNoConstants.js';
import moment from 'moment';
import {cellStyleB, cellStyleRB} from '@/components/Iui/const.js';

export default {
  components: {
    PrjList,
    ReportViewer,
  },
  data() {
    return {
      loadSearchData: [],
      Options: options(this),
      searchInfo: {
        investMm: '', //  출역년월
        searchSe: 'name',
        searchNm: '',
      },
      fomNo: 0,
      ozContent: {},
      internalCellStyle: {},
      internalRows: [],
      excelRows: [
        {
          value: [
            {value: '인적사항', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '출역현황', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '노무비', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '공제내역', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '차감지급액', style: {...cellStyleRB}},
            {value: '소속', style: {...cellStyleB}},
          ],
        },
        {
          value: [
            {value: 'ID', style: {...cellStyleRB}},
            {value: '성명', style: {...cellStyleRB}},
            {value: '주민번호', style: {...cellStyleRB}},
            {value: '직종', style: {...cellStyleRB}},
            {value: '주소', style: {...cellStyleRB}},
            {value: '1', style: {...cellStyleRB}},
            {value: '2', style: {...cellStyleRB}},
            {value: '3', style: {...cellStyleRB}},
            {value: '4', style: {...cellStyleRB}},
            {value: '5', style: {...cellStyleRB}},
            {value: '6', style: {...cellStyleRB}},
            {value: '7', style: {...cellStyleRB}},
            {value: '8', style: {...cellStyleRB}},
            {value: '9', style: {...cellStyleRB}},
            {value: '10', style: {...cellStyleRB}},
            {value: '11', style: {...cellStyleRB}},
            {value: '12', style: {...cellStyleRB}},
            {value: '13', style: {...cellStyleRB}},
            {value: '14', style: {...cellStyleRB}},
            {value: '15', style: {...cellStyleRB}},
            {value: '16', style: {...cellStyleRB}},
            {value: '17', style: {...cellStyleRB}},
            {value: '18', style: {...cellStyleRB}},
            {value: '19', style: {...cellStyleRB}},
            {value: '20', style: {...cellStyleRB}},
            {value: '21', style: {...cellStyleRB}},
            {value: '22', style: {...cellStyleRB}},
            {value: '23', style: {...cellStyleRB}},
            {value: '24', style: {...cellStyleRB}},
            {value: '25', style: {...cellStyleRB}},
            {value: '26', style: {...cellStyleRB}},
            {value: '27', style: {...cellStyleRB}},
            {value: '28', style: {...cellStyleRB}},
            {value: '29', style: {...cellStyleRB}},
            {value: '30', style: {...cellStyleRB}},
            {value: '31', style: {...cellStyleRB}},
            {value: '출역일수', style: {...cellStyleRB}},
            {value: '단가', style: {...cellStyleRB}},
            {value: '총액', style: {...cellStyleRB}},
            {value: '소득세', style: {...cellStyleRB}},
            {value: '지방소득세', style: {...cellStyleRB}},
            {value: '국민연금', style: {...cellStyleRB}},
            {value: '건강보험', style: {...cellStyleRB}},
            {value: '고용보험', style: {...cellStyleRB}},
            {value: '장기요양', style: {...cellStyleRB}},
            {value: '합계', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleRB}},
            {value: '', style: {...cellStyleB}},
          ],
        },
      ],
      excelDatas: [
        {
          value: [{value: '인적사항11'}],
        },
      ],
      merges: [
        {firstRow: 0, lastRow: 0, firstCol: 0, lastCol: 4},
        {firstRow: 0, lastRow: 0, firstCol: 5, lastCol: 35},
        {firstRow: 0, lastRow: 0, firstCol: 36, lastCol: 38},
        {firstRow: 0, lastRow: 0, firstCol: 39, lastCol: 45},
        {firstRow: 0, lastRow: 1, firstCol: 46, lastCol: 46},
        {firstRow: 0, lastRow: 1, firstCol: 47, lastCol: 47},
      ],
    };
  },
  created() {
    this.addFuncPrj(this.onSearchLastCloseMM);
    this.addFuncPrint(this.callReport);
  },
  methods: {
    onSearchFinish(e) {
      let count = e.sheet.getTotalRowCount();

      for (var i = 0; i < count; i = i + 2) {
        let row1 = e.sheet.getRowByIndex(i + 1);
        let row2 = e.sheet.getRowByIndex(i + 2);

        // 병합 설정
        e.sheet.setMergeRange(row1, 'empNo', row2, 'empNo');
        e.sheet.setMergeRange(row1, 'deductSum', row2, 'deductSum');
        e.sheet.setMergeRange(row1, 'actPayAmt', row2, 'actPayAmt');
        e.sheet.setMergeRange(row1, 'blnDepNm', row2, 'blnDepNm');
        e.sheet.setMergeRange(row2, 'name', row2, 'jkdNm');

        // 정렬 설정
        e.sheet.setAttribute(row2, 'name', 'Align', 'left', 1);
      }
    },
    onRenderFirstFinish() {
      this.sheet.setAutoMerge(null, 6);
    },
    async onSearchLastCloseMM() {
      const response = await selectLastInvestMm(this.searchInfo);
      this.searchInfo.investMm = response.data.investMm ? response.data.investMm : $_getYearMonth();
      this.onSearch();
    },
    async onSearch() {
      if (this.searchInfo.investMm == '') {
        this.$alert({title: '출역년월', message: '출역년월을 선택해 주세요.'});
        return;
      }

      const response = await selectList(this.searchInfo);
      this.loadSearchData = response.data;
    },
    async onClickDate(searchDateType) {
      let param = {
        ...this.searchInfo,
        searchDateType,
      };
      const response = await selectLastInvestMm(param);
      if (response.data.investMm) {
        this.searchInfo.investMm = response.data.investMm;
        this.onSearch();
      } else {
        this.$alert({
          title: '노임대장',
          message: `${searchDateType == 'PREV' ? '이전' : '다음'} 노임대장이 없습니다.`,
        });
      }
    },
    async onReportViewer() {
      if (this.searchInfo.investMm == '') {
        this.$alert({title: '출역년월', message: '출역년월을 선택해 주세요.'});
        return;
      }
      let response = await selectPayStateList(this.searchInfo);
      const data = response.data;
      for (let key in data) {
        data[key].monthlyDay = $_numberFormat(data[key].monthlyDay, true, null, null);
        data[key].actPayAmt = $_numberFormat(data[key].actPayAmt, true, null, null);
        data[key].incometax = $_numberFormat(data[key].incometax, true, null, null);
        data[key].resitax = $_numberFormat(data[key].resitax, true, null, null);
      }
      let laborList = JSON.stringify({laborList: data});

      this.fomNo = FOM_NO.PAYMENT_STATEMENT;
      this.ozContent = {
        laborList: laborList,
      };

      this.$modal.show('ReportViewerModal');
    },
    onExceldownload() {
      if (this.searchInfo.investMm == '') {
        this.$alert({title: '출역년월', message: '출역년월을 선택해 주세요.'});
        return;
      }
      this.exceldownload();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    formattedSheetData() {
      const PrjInfo = {
        title: `${moment(this.searchInfo.investMm).format('M월')} 지급명세서`,
        prjNm: `현장명 : ${this.getPrjInfo().prjNm}`,
      };

      const Summary = {
        monthlyDay: '0',
        priceLAmt: '0',
        monthlyAmt: '0',
        incometax: '0',
        resitax: '0',
        pensionAmt: '0',
        healthInsurAmt: '0',
        employInsurAmt: '0',
        careAmt: '0',
        deductSum: '0',
        actPayAmt: '0',
      };

      const LaborList = [];
      for (let i = 0; i < this.loadSearchData.length / 2; i++) {
        const prev = this.loadSearchData[i * 2];
        const next = this.loadSearchData[i * 2 + 1];

        const obj = {
          empNo: prev.empNo,
          name: prev.name,
          regiNo: prev.regiNo,
          jkdNm: prev.jkdNm,
          adr1: prev.adr1,
          monthlyDay: String(prev.monthlyDay),
          priceLAmt: !prev.priceLAmt ? '0' : $_numberFormat(prev.priceLAmt, true),
          monthlyAmt: !next.priceLAmt ? '0' : $_numberFormat(next.priceLAmt, true),
          incometax: !prev.incometax ? '0' : $_numberFormat(prev.incometax, true),
          resitax: !next.incometax ? '0' : $_numberFormat(next.incometax, true),
          pensionAmt: !prev.pensionAmt ? '0' : $_numberFormat(prev.pensionAmt, true),
          healthInsurAmt: !next.pensionAmt ? '0' : $_numberFormat(next.pensionAmt, true),
          employInsurAmt: !prev.employInsurAmt ? '0' : $_numberFormat(prev.employInsurAmt, true),
          careAmt: !next.careAmt ? '0' : $_numberFormat(next.careAmt, true),
          deductSum: !prev.deductSum ? '0' : $_numberFormat(prev.deductSum, true),
          actPayAmt: !prev.actPayAmt ? '0' : $_numberFormat(prev.actPayAmt, true),
          blnDepNm: prev.blnDepNm,
        };

        Summary.monthlyDay = Number(Summary.monthlyDay) + Number($_numberFormat(obj.monthlyDay, false));
        Summary.priceLAmt = Number(Summary.priceLAmt) + Number($_numberFormat(obj.priceLAmt, false));
        Summary.monthlyAmt = Number(Summary.monthlyAmt) + Number($_numberFormat(obj.monthlyAmt, false));
        Summary.incometax = Number(Summary.incometax) + Number($_numberFormat(obj.incometax, false));
        Summary.resitax = Number(Summary.resitax) + Number($_numberFormat(obj.resitax, false));
        Summary.pensionAmt = Number(Summary.pensionAmt) + Number($_numberFormat(obj.pensionAmt, false));
        Summary.healthInsurAmt = Number(Summary.healthInsurAmt) + Number($_numberFormat(obj.healthInsurAmt, false));
        Summary.employInsurAmt = Number(Summary.employInsurAmt) + Number($_numberFormat(obj.employInsurAmt, false));
        Summary.careAmt = Number(Summary.careAmt) + Number($_numberFormat(obj.careAmt, false));
        Summary.deductSum = Number(Summary.deductSum) + Number($_numberFormat(obj.deductSum, false));
        Summary.actPayAmt = Number(Summary.actPayAmt) + Number($_numberFormat(obj.actPayAmt, false));

        for (let i = 1; i <= 31; i++) {
          const value = i < 16 ? prev[`d${i}`] : next[`d${i - 15}`];
          obj[`d${i}`] = !value ? '' : Number(value).toFixed(1);
        }
        LaborList.push(obj);
      }

      Summary.monthlyDay = $_numberFormat(Summary.monthlyDay, true);
      Summary.priceLAmt = $_numberFormat(Summary.priceLAmt, true);
      Summary.monthlyAmt = $_numberFormat(Summary.monthlyAmt, true);
      Summary.incometax = $_numberFormat(Summary.incometax, true);
      Summary.resitax = $_numberFormat(Summary.resitax, true);
      Summary.pensionAmt = $_numberFormat(Summary.pensionAmt, true);
      Summary.healthInsurAmt = $_numberFormat(Summary.healthInsurAmt, true);
      Summary.employInsurAmt = $_numberFormat(Summary.employInsurAmt, true);
      Summary.careAmt = $_numberFormat(Summary.careAmt, true);
      Summary.deductSum = $_numberFormat(Summary.deductSum, true);
      Summary.actPayAmt = $_numberFormat(Summary.actPayAmt, true);

      return {
        PrjInfo,
        Summary,
        LaborList,
      };
    },
    callReport() {
      if (this.loadSearchData.length > 0) {
        this.fomNo = FOM_NO.PAYROLL;

        const formattedSheetData = this.formattedSheetData();

        this.ozContent = {
          PrjInfo: JSON.stringify({PrjInfo: formattedSheetData.PrjInfo}),
          Summary: JSON.stringify({Summary: formattedSheetData.Summary}),
          LaborList: JSON.stringify({LaborList: formattedSheetData.LaborList}),
        };

        this.$modal.show('PayrollViewerModal');
      }
    },
    async exceldownload() {
      this.excelDatas = [];
      const formattedSheetData = this.formattedSheetData();

      formattedSheetData.LaborList.forEach(labor => {
        const val = [];
        val.push({value: labor.empNo});
        val.push({value: labor.name});
        val.push({value: labor.regiNo});
        val.push({value: labor.jkdNm});
        val.push({value: labor.adr1 || ''});
        val.push({doubleValue: labor.d1});
        val.push({doubleValue: labor.d2});
        val.push({doubleValue: labor.d3});
        val.push({doubleValue: labor.d4});
        val.push({doubleValue: labor.d5});
        val.push({doubleValue: labor.d6});
        val.push({doubleValue: labor.d7});
        val.push({doubleValue: labor.d8});
        val.push({doubleValue: labor.d9});
        val.push({doubleValue: labor.d10});
        val.push({doubleValue: labor.d11});
        val.push({doubleValue: labor.d12});
        val.push({doubleValue: labor.d13});
        val.push({doubleValue: labor.d14});
        val.push({doubleValue: labor.d15});
        val.push({doubleValue: labor.d16});
        val.push({doubleValue: labor.d17});
        val.push({doubleValue: labor.d18});
        val.push({doubleValue: labor.d19});
        val.push({doubleValue: labor.d20});
        val.push({doubleValue: labor.d21});
        val.push({doubleValue: labor.d22});
        val.push({doubleValue: labor.d23});
        val.push({doubleValue: labor.d24});
        val.push({doubleValue: labor.d25});
        val.push({doubleValue: labor.d26});
        val.push({doubleValue: labor.d27});
        val.push({doubleValue: labor.d28});
        val.push({doubleValue: labor.d29});
        val.push({doubleValue: labor.d30});
        val.push({doubleValue: labor.d31});
        val.push({doubleValue: labor.monthlyDay});
        val.push({doubleValue: String(labor.priceLAmt || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.monthlyAmt || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.incomeTax || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.resitax || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.pensionAmt || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.healthInsurAmt || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.employInsurAmt || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.careAmt || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.deductSum || 0).replace(/,/g, '')});
        val.push({doubleValue: String(labor.actPayAmt || 0).replace(/,/g, '')});
        val.push({value: labor.blnDepNm});

        this.excelDatas.push({
          value: val,
        });
      });

      this.internalRows = [];
      this.excelRows.forEach(row => {
        if (Array.isArray(row)) {
          const temp = {value: []};
          row.forEach(col => {
            if (col.style?.type) {
              this.internalCellStyle.type = 'TEXT';
            }

            temp.value.push({value: col, style: this.internalCellStyle});
          });
          this.internalRows.push(temp);
        } else {
          row.value.forEach(col => {
            if (col.style?.type) {
              this.internalCellStyle.type = 'TEXT';
            }
          });
          this.internalRows.push(row);
        }
      });
      this.loading = true;
      try {
        const response = await axios.post(
          `/excel/download`,
          {
            rows: this.internalRows,
            dataList: this.excelDatas,
            merges: this.merges,
          },
          {
            responseType: 'blob',
          }
        );

        this.size = $formatBytes(new Blob([response.data]).size);
        const url = window.URL.createObjectURL(new Blob([response.data], {type: $getMineType('xlsx')}));
        this.link = document.createElement('a');
        this.link.href = url;
        this.link.setAttribute('download', '노임대장.xlsx');
        document.body.appendChild(this.link);
        this.link.click();
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  },
};
</script>
